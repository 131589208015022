<template>
  <div class="dropdown">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{label}}</a>

    <div class="dropdown-menu">
      <a class="dropdown-item" @click.prevent.stop="selectItem(award)" :class="{'active': value.indexOf(award.id.toString()) > -1}" href="#" v-for="award in awards" :key="`award_filter_${award.id}`">{{award.name}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'value'],
  computed: {
    awards() {
      return this.$store.state.awards;
    }
  },
  methods: {
    selectItem(obj) {
      if(this.value.indexOf(obj.id.toString()) > -1) {
        const newVal = [...this.value];
        newVal.splice(
          newVal.indexOf(obj.id.toString()),
          1
        )
        this.$emit('input', newVal)
      }
      else {
        this.$emit('input', [
          ...this.value,
          obj.id.toString()
        ])
      }
    }
  }
}
</script>