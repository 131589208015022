var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-section"},[_c('div',{staticClass:"filters"},[_c('label',[_vm._v("Filter by")]),_c('ul',[(_vm.me.is_admin)?_c('li',[_c('user-groups-filter',{attrs:{"label":_vm.filter_label('groups')},model:{value:(_vm.filters.user_groups),callback:function ($$v) {_vm.$set(_vm.filters, "user_groups", $$v)},expression:"filters.user_groups"}})],1):_vm._e(),_c('li',[_c('user-segment-filter',{attrs:{"label":_vm.filter_label('segments')},model:{value:(_vm.filters.user_segments),callback:function ($$v) {_vm.$set(_vm.filters, "user_segments", $$v)},expression:"filters.user_segments"}})],1),_c('li',[_c('awards-filter',{attrs:{"label":_vm.filter_label('awards')},model:{value:(_vm.filters.awards),callback:function ($$v) {_vm.$set(_vm.filters, "awards", $$v)},expression:"filters.awards"}})],1)])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"table-wrap"},[(_vm.activity !== null)?[(_vm.activity.length > 0)?_c('ht-sortable-table',{attrs:{"rows":_vm.activity,"columns":[
            'Date',
            'Given by',
            'Recipients',
            'Award earned',
            'Points earned',
            'Message',
          ],"sortable":{
            '1': 'created_at',
            '2': 'created_by.name',
            '4': 'award.name',
            '5': 'points',
          }},scopedSlots:_vm._u([{key:"rows",fn:function({rows, sort_by}){return _vm._l((rows),function(row,index){return _c('tr',{key:`activity_row_${sort_by}_${index}`},[_c('td',[_vm._v(" "+_vm._s(_vm.$formatDate(row.created_at))+" ")]),_c('td',[(row.created_by)?_c('user-card',{attrs:{"user":row.created_by,"height":22}}):_vm._e()],1),_c('td',_vm._l((row.for_users),function(for_user){return _c('user-card',{key:`award_${row.id}_foruser_${for_user.id}`,attrs:{"user":for_user,"height":22}})}),1),_c('td',[_vm._v(_vm._s(row.award.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.$formatPoints(row.points))+" ")]),_c('td',[_vm._v(" "+_vm._s(row.message)+" ")])])})}}],null,false,1537003972)}):_c('div',{staticClass:"empty-state"},[_vm._v(" No activity found with these filters. ")])]:_c('loading-indicator')],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }